<template>
  <b-card>
    <div class="terms-of-payment">
      <h2>Terms of Payment</h2>

      <p>
        Thank you for choosing our services/products. Please read the following terms of payment carefully before making any transactions.
      </p>

      <div class="payment-section">
        <h3>1. Payment Methods:</h3>
        <ul>
          <li>Credit Cards: We currently accept Visa, MasterCard, and American Express.</li>
          <li>Debit Cards: We accept debit cards associated with major banks.</li>
          <li>Online Payments: Secure online payment options are available through our website.</li>
        </ul>
      </div>

      <div class="payment-section">
        <h3>2. Payment Due Date:</h3>
        <p>
          All payments are due on the specified due date mentioned in your invoice. Failure to make payment by the due date may result in late fees.
        </p>
      </div>

      <!-- Add other sections following a similar structure -->

      <div class="contact-information">
        <h3>8. Contact Information:</h3>
        <p>
          For any payment-related inquiries, please contact our billing department at
          <span class="contact-info">support@ophelio.de</span>. Our team is here to assist you.
        </p>
      </div>

      <!-- Add the security and changes to terms sections as needed -->

      <div class="footer">
        <p>
          By making a payment, you acknowledge that you have read and agree to these terms of payment. Thank you for your cooperation.
        </p>
        <p>
          Ophelio.de<br>
          support@ophelio.de<br>
          
        </p>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';

export default {
  components: {
    BCard,
  },
  setup() {},
};
</script>

<style scoped>
.terms-of-payment {
  font-family: Arial, sans-serif;
}

.payment-section, .contact-information {
  margin-bottom: 20px;
}

.contact-info {
  font-weight: bold;
}

.footer {
  margin-top: 20px;
  font-size: 0.8em;
}
</style>
